// patternlib.js - by 80 DAYS


function patternlib() {
	
	verticalCenter();
	
	scroller();
	
	//filterOffers();
	
}



// Utility fuctions provided by https://remysharp.com/2010/07/21/throttling-function-calls

function debounce(fn, delay) {
  var timer = null;
  return function () {
    var context = this, args = arguments;
    clearTimeout(timer);
    timer = setTimeout(function () {
      fn.apply(context, args);
    }, delay);
  };
}


function throttle(fn, threshhold, scope) {
  threshhold || (threshhold = 250);
  var last,
      deferTimer;
  return function () {
    var context = scope || this;

    var now = +new Date,
        args = arguments;
    if (last && now < last + threshhold) {
      // hold on to it
      clearTimeout(deferTimer);
      deferTimer = setTimeout(function () {
        last = now;
        fn.apply(context, args);
      }, threshhold);
    } else {
      last = now;
      fn.apply(context, args);
    }
  };
}




// Veritcal Centering

function verticalCenter() {
	$('.vertical-center').each( function () {
		$(this).wrapInner('<div class="vertical-center-table"><div class="vertical-center-row"><div class="vertical-center-cell"></div></div></div>');
	});
}






// Page scrolling
function scroller() {
	
	$('.scroller').on('click', function () {
		
		var scrollBelow;
		var scrollSpeed = 750;
		
		if ( $(this).data('scroll-below') != undefined ) {
			scrollBelow = '#' + $(this).data('scroll-below');
		} else {
			scrollBelow = $(this);
		}
		
		scrollBelow = $('.home-slider');
		
		if ( $(this).data('scroll-speed') != undefined ) {
			scrollSpeed = $(this).data('scroll-speed');
		}
		
		$('html, body').animate({
			scrollTop: $( scrollBelow ).outerHeight()
		}, scrollSpeed);
	});
	
}





// Filterable offers
function filterOffers() {
	
	var $_filterButtons = $('#offer-filters button');
	var $_filterItems = $('.offer-grid-item');

	$('#offer-filters button').on('click', function () {
		
		$(this).toggleClass('on');
		
		// Clear the "All" button
		/*if ( !$(this).hasClass('all') ) {
			$('#offer-filters button.all').removeClass('on');
		} else {
			// All button has been clicked, so show all buttons and return
			$('#offer-filters button').removeClass('on');
			$('#offer-filters button.all').addClass('on');
			$_filterItems.fadeIn();
			return;
		}*/
		
		if ( $(this).hasClass('on') ) {
			return; // do nothing
		} else if ( $(this).hasClass('all') ) {
			$('#offer-filters button').removeClass('on');
			$(this).addClass('on');
			$_filterItems.fadeIn();
			return;
		} else {
			$('#offer-filters button').removeClass('on');
			$(this).addClass('on');
		}
		
		var currentCats = '';
		
		// Loop through the "on" buttons and build a class selector string of the active 
		$_filterButtons.each( function () {
			if ( $(this).hasClass('on') ) {
				currentCats += '.' + $(this).data('category') + ', ';
			}
		});
		currentCats = currentCats.substr(0, currentCats.length - 2); // Remove the last comma and space from the string, otherwise jQuery borks
		
		// No categories selected so show them all
		if ( currentCats == '' ) {
			$_filterItems.fadeIn();
			return;
		}
		
		// Hide or show the elements that match the class
		$_filterItems.each( function () {
			if ( $(this).is(currentCats) ) {
				$(this).fadeIn();
			} else {
				$(this).fadeOut();
			}
		});
		
	});	
	
	// Pre-filter the offers
	// Get the url parameters
	var urlParams = {};
	var e,
		a = /\+/g,  // Regex for replacing addition symbol with a space
		r = /([^&=]+)=?([^&]*)/g,
		d = function (s) { return decodeURIComponent(s.replace(a, " ")); },
		q = window.location.search.substring(1);

	while (e = r.exec(q))
		urlParams[d(e[1])] = d(e[2]);

	if (urlParams['category']) {
		$('#offer-filters button[data-category="' + urlParams['category'] + '"]').trigger('click');	
	}
	
	
	if ( window.location.hash != '' ) {
		var hashValue = window.location.hash.substring(1); // remove the '#' from the start of the string
		$('button[data-category="' + hashValue + '"]').trigger('click');
	}
	
	
}

